import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Hidden Gems`}</h2>
    <p>{`Games that aren't highly acclaimed or super popular but are fun or unique enough to deserve a mention:`}</p>
    <h3><a parentName="h3" {...{
        "href": "https://superflightgame.com/"
      }}>{`Superflight`}</a></h3>
    <p>{`Cheap but very fun game. I found myself playing this game for a decent amount of time. Unlike many games of this whole "randomly-generated world" genre, Superflight has a beautiful variety of colors and generated worlds. I won't lie, some worlds look similar, but because your focus is to constantly find dangerous but doable places to fly, even worlds that look quite similar can have a completely different flying experience.`}</p>
    <p>{`And the random generation is really good. I found myself at an impasse of saving a randomly generated world and wanting to keep flying without taking a break`}</p>
    <h3><a parentName="h3" {...{
        "href": "https://www.supergiantgames.com/games/bastion/"
      }}>{`Bastion`}</a></h3>
    <p>{`A `}<em parentName="p">{`well-known`}</em>{` gem, technically, Bastion is an amazing action hack 'n slash game with RPG elements. The gameplay involves wielding two weapons and their load-outs, and traversing across floating platforms.`}</p>
    <p>{`Beyond gameplay, the story in this game, the design, and music, are an experience to definitely have. I highly recommend it and I recommend watching game analysis YT videos after beating it for further breakdown.`}</p>
    <h2>{`Big games`}</h2>
    <h3>{`Final Fantasy I`}</h3>
    <h3>{`Final Fantasy II`}</h3>
    <h3>{`Final Fantasy III`}</h3>
    <h3>{`Pokemon SoulSilver`}</h3>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      